import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Button,
  Heading,
  Image,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Modal,
} from '@chakra-ui/react';


import Bank from '@invitato/helpers/dist/constants/banks';
import txtWording from './locales';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';

function ModalBank({ onClose, visible, onCopyText, lang }) {
  const renderBank = (bank, userName, bankNumber) => {
    return (
      <Box padding="16px 0" fontFamily="serif" textAlign="center">
        <Center>
          <Image src={bank.logo} marginBottom="8px" maxWidth="80px" />
        </Center>
        <Text color="secondaryColorText" marginTop="0" fontWeight="bold">
          {bankNumber}
        </Text>
        <Text color="black">{userName}</Text>
        <Center>
          <Button
            size="sm"
            fontWeight="normal"
            colorScheme="blackAlpha"
            color="btnPrimaryColor"
            variant="outline"
            marginTop="16px"
            fontFamily="body"
            onClick={() => onCopyText(bankNumber)}
          >
            {txtWording.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Modal size="full" onClose={onClose} isOpen={visible}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent maxWidth="500px">
        <ModalCloseButton marginTop="12px" {...DEFAULT_BUTTON_PROPS} />
        <ModalBody>
          <Heading
            textAlign="center"
            fontWeight="normal"
            fontSize="3xl"
            color="secondaryColorText"
            marginTop="24px"
          >
            Bank Transfer
          </Heading>
          <Text align="center" fontFamily="normal" fontSize="sm" margin="16px 0" color="black">
            {txtWording.modalDesc[lang]}
          </Text>
          {renderBank(Bank.bca, 'CHRISTINE', '8300226303')}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

ModalBank.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

ModalBank.defaultProps = {
  lang: 'id',
};

export default ModalBank;
